import React from 'react'
import Parallax from '../Parallax'
import Plan from './Plan'
import Definition from './Definition'
import Programme from './Programme'
import Affiche from './Affiche'

function Accueil() {
  return (
    <div>
      {/*<Parallax image="ordre.jpg" alt="Bitardbourg" title="Bienvenue à Bitardbourg" />*/}
      <Parallax image="pierre-levee.jpg" alt="Pierre Levée" title="Bienvenue à Bitardbourg"/>
      <div className="row">
        <div className="col s12 m12 l6">
          <Affiche/>
        </div>
        <div className="col s12 m12 l6">
          <Programme/>
        </div>
      </div>
      <Plan/>
      <Definition/>
      <Parallax image="chateau-eau.jpg" alt="Bitardbourg"/>
    </div>
  )
}

export default Accueil
